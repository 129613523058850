@import "~ngx-toastr/toastr";

$base-color: rgb(77, 19, 209);
$row-color: #2574a950;
$base-text-color: rgba(18, 14, 21, 1);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.full-width {
  min-width: 100% !important;
}

.mat-expansion-panel-body {
  padding: 0px !important;
}

.spacer {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
}

.table-responsive {
  @media screen and (max-width: 960px) {
    .mat-table {
      border: 0;
      vertical-align: middle;
    }

    .mat-table caption {
      font-size: 1em;
    }

    .mat-table .mat-header-cell {
      border: 10px solid;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    .mat-table .mat-row {
      border-bottom: 5px solid #ddd;
      display: block;
    }
    /*
    .mat-table .mat-row:nth-child(even) {background: #CCC}
    .mat-table .mat-row:nth-child(odd) {background: #FFF}
    */
    .mat-table .mat-cell {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 1em;
      text-align: right;
      font-weight: bold;
      height: 30px;
      margin-bottom: 4%;
    }
    .mat-table .mat-cell:before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      text-transform: uppercase;
      font-weight: normal;

      font-size: 0.85em;
    }
    .mat-table .mat-cell:last-child {
      border-bottom: 0;
    }
    .mat-table .mat-cell:first-child {
      margin-top: 4%;
    }
  }
}

button:focus {
  outline: 0;
}

.spacer {
  flex: 1 1 auto;
}

.page-toolbar {
  background-color: $base-color;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 32px;
}

.mat-row:hover {
  background-color: rgba(66, 66, 66, 0.1);
}

.table-wrap {
  overflow-x: auto;
}

table.mat-table {
  min-width: 1000px;
  width: 100%;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $base-color !important;
}

.key-def {
  font-size: 18px;
  display: flex;
  margin-bottom: 4px;
}

.value-def {
  background-color: rgba(230, 230, 230, 1);
  padding: 8px;
  border-radius: 5px;
  color: rgba(100, 100, 100, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.key-value-wrap {
  overflow-x: hidden;
  margin-bottom: 16px;
}

.badge-chip {
  padding: 8px;
  border-radius: 5px;
  color: #ffffff;
}

.badge-chip-success {
  background-color: #3fb53f;
}

.badge-chip-primary {
  background-color: #2574a9;
}

.mat-form-field {
  width: 100%;
}
